import React, { Component } from 'react';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import ScrollToTop from 'react-scroll-up';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import styles from '../../css/officialSongs.module.css';
import ResourceTable from '../../components/ResourceTable/ResourceTable';
import TitleBar from '../../components/TitleBar/TitleBar';

class OfficialSongs extends Component {
  render() {
    const { data } = this.props;
    const { edges: resources } = data.allOfficialSongsListJson;
    const mappedResources = resources.map(resource => resource.node);

    return (
      <Layout>
        <Helmet
          title="VEYM | Official Songs"
          meta={[
            {
              name: 'description',
              content: `Official Songs (Ca Chính Thức) - Songs used during TNTT activities`,
            },
          ]}
        />
        <PageHeading>Official Songs</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/resources">Resources</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Official Songs</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <Container>
          <br />
          <ResourceTable resources={mappedResources} bordered />

          <br />
          <br />
        </Container>
        <br />
        <TitleBar>Official Songs</TitleBar>
        <br />
        <br />
        <Container>
          <div className={styles.songs}>
            <Row>
              <Col md={4}>
                <ListGroup>
                  <ListGroupItem tag="a" href="#quocca">
                    1. Quốc Ca Việt Nam
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#tnthc">
                    2. Thiếu Nhi Tân Hành Ca
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#anca">
                    3. Ấu Nhi Ca
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#tnca">
                    4. Thiếu Nhi Ca
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#nsca">
                    5. Nghĩa Sĩ Ca
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#hhca">
                    6. Hiệp Hành Ca
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#kinhht">
                    7. Kinh Huynh Trưởng
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#kinhdt">
                    8. Kinh Đội Trưởng
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#kinhdangngay">
                    9. Kinh Dâng Ngày (song)
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#kinhdangdem">
                    10. Kinh Dâng Đêm
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#goilua">
                    11. Gọi Lửa
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#chaoluathieng">
                    12. Chào Lửa Thiêng
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#mangluavetim">
                    13. Mang Lửa Về Tim
                  </ListGroupItem>
                </ListGroup>
              </Col>
              <Col md={4}>
                <ListGroup>
                  <ListGroupItem tag="a" href="#kinhtruockhian">
                    14. Kinh Trước Khi Ăn
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#kinhsaukhian">
                    15. Kinh Sau Khi Ăn
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#catambiet">
                    16. Ca Tạm Biệt
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#loichua">
                    17. Lời Chúa
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#lanhnhanloichua">
                    18. Lãnh Nhận Lời Chúa
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#khauhieuthieunhi">
                    19. Khẩu Hiệu Thiếu Nhi
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#cauchuyentinhthuong">
                    20. Câu Chuyện Tình Thương
                  </ListGroupItem>
                </ListGroup>
              </Col>
              <Col md={4}>
                <ListGroup>
                  <ListGroupItem color="info" tag="a" href="#othersongs">
                    Other Songs
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#covetay">
                    Cờ Về Tay
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#tuatrenco">
                    Tua Trên Cờ
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#themtua">
                    Thêm Tua
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#goghe">
                    Gồ Ghê
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#hoanhoanhnay">
                    Hoan Hô Anh Này
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#hayqua">
                    Hay Quá
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#hoanho">
                    Hoan Hô
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#ohay">
                    Ô! Hay!
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#caituatrenco">
                    Cái Tua Trên Cờ
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#ongsaosang">
                    Ông Sao Sáng
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={12} md={12}>
                <b id="quocca">1. Quốc Ca Việt Nam</b>
                <br />
                <br />
                Này công dân ơi, Đứng lên đáp lời sông núi,
                <br />
                đồng lòng cùng đi hy sinh tiếc gì thân sống.
                <br />
                Vì tương lai quốc dân cùng xông pha khói tên,
                <br />
                làm sao cho núi sông từ nay luôn vững bền.
                <br />
                <br />
                Dù cho thây phơi trên gươm giáo,
                <br />
                thù nước lấy máu đào đem báo.
                <br />
                Nòi giống, lúc biến phải cần giải nguy,
                <br />
                người công dân luôn vững bền tâm trí,
                <br />
                hùng tráng quyết chiến đấu làm cho khắp nơi
                <br />
                vang tiếng người nước Nam cho đến muôn đời.
                <br />
                <br />
                Công dân ơi, mau hiến thân dưới cờ.
                <br />
                Công dân ơi, mau làm cho cõi bờ,
                <br />
                thoát cơn tàn phá, vẻ vang nòi giống
                <br />
                xứng danh nghìn năm dòng giống Lạc Hồng.
                <br />
                <hr />
                <br />
                <b id="tnthc">2. Thiếu Nhi Tân Hành Ca</b>
                <br />
                <br />
                Thiếu Nhi Việt Nam đứng lên trong giai đoạn mới,
                <br />
                theo tiếng Giáo Hội và tiếng quê hương kêu mời.
                <br />
                Được trang bị dũng mạnh bằng tinh thần mới,
                <br />
                tuổi trẻ Việt Nam hăng hái xây thế hệ ngày mai.
                <br />
                <br />
                Cùng đi hỡi các Thiếu Nhi, cùng đi với Chúa Kitô.
                <br />
                Nguồn Sống Thánh Thể chan hòa,
                <br />
                là lý tưởng của người Thiếu Nhi hôm nay!
                <br />
                <br />
                Thiếu Nhi Việt Nam quyết tâm trong giai đoạn mới,
                <br />
                thánh hóa môi trường rèn những khả năng phi thường.
                <br />
                Bằng nguyện cầu hy sinh và một bầu khí mới,
                <br />
                tuổi trẻ Việt Nam đem Chúa cho giới trẻ mọi nơi.
                <br />
                <hr />
                <br />
                <b id="anca">3. Ấu Nhi Ca</b>
                <br />
                <br />
                Đoàn em như những mầm mạ non xanh tươi,
                <br />
                luôn vui cười luôn ngoan ngoãn sống đời Ấu Nhi.
                <br />
                Nguyện bền chí theo gương Chúa Giê-Su tuổi thơ,
                <br />
                dưới bóng cờ, em mang Chúa cho tuổi Ấu thơ.
                <br />
                <hr />
                <br />
                <b id="tnca">4. Thiếu Nhi Ca</b>
                <br />
                <br />
                Em Thiếu Nhi ơi, như ngàn tia sáng huy hoàng,
                <br />
                vào buổi rạng đông đến mang nguồn hơi ấm.
                <br />
                Em Thiếu Nhi ơi, em đẹp như hoa hướng dương,
                <br />
                tươi dịu như hơi gió xuân, đem yên vui đến muôn người.
                <br />
                <br />
                Em Thiếu Nhi ơi, mắt nhìn em ví sao trời,
                <br />
                nụ cười trên môi xóa tan màu u tối.
                <br />
                Em Thiếu Nhi ơi, em là chiến sĩ Phúc Âm,
                <br />
                gian khổ nguy khó chớ nao, vui mang Chúa đến cho người.
                <br />
                <hr />
                <br />
                <b id="nsca">5. Nghĩa Sĩ Ca</b>
                <br />
                <br />
                Vui ca lên Nghĩa sĩ đời đầy diễm phúc.
                <br />
                Hồn thanh xuân say niềm lý tưởng cao xa.
                <br />
                Vì Nghĩa ra đi là đi Chinh Phục.
                <br />
                Đường xa bóng Chúa đang chờ đợi ta.
                <br />
                <br />
                Một đời trẻ trung thành nồng hậu sức thiêng.
                <br />
                Hùng dũng trung thành một dạ trung kiên.
                <br />
                Tình bằng hữu, chân thành một dạ vững kiên.
                <br />
                Tôn quý trách nhiệm sống gương hy sinh.
                <br />
                <br />
                Nghĩa Sĩ Việt Nam sẵn sàng hùng tráng,
                <br />
                trong sạch hăng hái và quyết tiến bước.
                <br />
                Lửa thần nung nấu tâm can không ngừng.
                <br />
                Mầm thiêng ta gieo khắp nơi xa gần.
                <br />
                <br />
                Lý tưởng siêu nhiên thành tâm yêu mến.
                <br />
                Danh Cha cả sáng, nước Cha trị đến.
                <br />
                Noi gương nhiệt thành của Thánh Phaolô
                <br />
                Xông pha lên đường làm chứng Phúc Âm.
                <br />
                <hr />
                <br />
                <b id="hhca">6. Hiệp Hành Ca</b>
                <br />
                <br />
                Dưới bóng cờ nêu gương Người Hiệp Sĩ trung kiên.
                <br />
                Vì yêu Chúa ta nguyện Dấn Thân.
                <br />
                Mang Đức Tin chiếu dọi vào Đình Trời.
                <br />
                Mang mến yêu thắm gội vào lòng người.
                <br />
                Người Hiệp Sĩ sống gương anh hào.
                <br />
                <br />
                Là Hiệp Sĩ cố gắng chiến đấu, cho nhà Đức Tin nêu gương cao
                trung,
                <br />
                ta nguyên hiến thân, cho Giáo Hội trường sinh.
                <br />
                Là Hiệp Sĩ, cố gắng chiến đấu, đem nguôn ủi an đi tôn non sông
                <br />
                công bình khắp nơi, cho Nước Việt trường tôn.
                <br />
                <br />
                Dưới bóng cờ noi gương Mẹ Maria và các Thánh Tử Đạo Nước Nam.
                <br />
                Cho giáo dân sống đạo nhiệt thành.
                <br />
                Cho Quốc Dân thái bình và thịnh vượng.
                <br />
                Người Hiệp Sĩ xứng danh Anh Hùng.
                <br />
                <hr />
                <br />
                <b id="kinhht">7. Kinh Huynh Trưởng</b>
                <br />
                <br />
                Lạy Chúa Giêsu Huynh Trưởng Tối Cao,
                <br />
                xin dạy con biết hy sinh cao thượng,
                <br />
                phụng sự Chúa và giúp ích mọi người.
                <br />
                Xin dạy con biết hy sinh không cần báo đáp,
                <br />
                luôn xông pha không ngại bão táp,
                <br />
                đẹp ý Cha trên trời, trong tình thương yêu hết mọi người.
                <br />
                <hr />
                <br />
                <b id="kinhdt">8. Kinh Đội Trưởng</b>
                <br />
                <br />
                Nguyện xin Giêsu anh cả từ nhân,
                <br />
                ngày đêm ban xuống muôn ơn phúc cho đội của con.
                <br />
                Giúp cho đoàn con nên những Tông Đồ nhiệt thành,
                <br />
                mở rộng nước Chúa cứu rỗi các linh hồn.
                <br />
                <br />
                Đời con hy sinh nguy khó chẳng nề.
                <br />
                Bền tâm bác ái yêu Chúa yêu người thiết tha.
                <br />
                Rước lễ mỗi ngày dâng lên Cha tất cả cuộc sống.
                <br />
                Xứng danh Tông Đồ của Chúa Giêsu.
                <br />
                <hr />
                <br />
                <b id="kinhdangngay">9. Kinh Dâng Ngày (song)</b>
                <br />
                <br />
                Ngày nay con dâng cho Chúa, xin Chúa thương nhận lòng trí chúng
                con.
                <br />
                Mọi cơn gian nan nguy khó, chúng con xin cầu theo ý Đức Giáo
                Hoàng sớm hôm.
                <br />
                <hr />
                <br />
                <b id="kinhdangdem">10. Kinh Dâng Đêm</b>
                <br />
                <br />
                Trời đã xế chiều, Giêsu ơi con nhờ tay Mẹ Maria,
                <br />
                mà dâng lên Chúa, dâng chúc lời cám ơn, dâng trót cả xác hồn.
                <br />
                Các việc con làm, các lời con xin, cùng với mọi khó nguy con
                chịu trót một ngày qua.
                <br />
                Cùng với bóng chiều tà, Giêsu Maria, con hòa ca, dâng về nơi bao
                la.
                <br />
                Chúa ban phép lành, một đêm ngủ an bình, hồn trong xác tươi
                xinh.
                <br />
                <hr />
                <br />
                <b id="goilua">11. Gọi Lửa</b>
                <br />
                <br />
                Lửa Thiêng ơi hãy đến, bừng sáng lên trong đêm âm u soi đời tăm
                tối bao nhiêu âu lo.
                <br />
                Lửa Thiêng ơi hãy đến, bừng cháy lên, mang cho đời ngàn ánh vinh
                quang vui hân hoan.
                <br />
                <hr />
                <br />
                <b id="chaoluathieng">12. Chào Lửa Thiêng</b>
                <br />
                <br />
                Ố ô ô ồ, ố ô ô ồ.
                <br />
                Cầm tay nhau quay vòng quanh lửa mới.
                <br />
                Lửa bốc cháy tâm hồn ta hăng hái.
                <br />
                Đón ánh lửa thiêng,
                <br />
                đây đoàn ta, chung lời ca bên lửa bập bùng.
                <br />
                <br />
                Bập bập bùng lửa thiêng reo vui, nhạc trầm trầm hoà ca chơi vơi,
                <br />
                lửa rực sáng chiếu đêm âm u
                <br />
                anh em ơi, ta cùng nhau lên tiếng ca rằng (vỗ tay 3 cái).
                <br />
                <br />
                Lửa linh thiêng soi màn đêm u tối.
                <br />
                Lửa cháy sáng ấm lòng ai lạnh lẽo.
                <br />
                Lửa thiêng muôn đời,
                <br />
                ta cùng vui đem lửa thiêng soi lòng mọi người.
                <br />
                Ố ô ô ồ, ố ô ô ồ.
                <br />
                <hr />
                <br />
                <b id="mangluavetime">13. Mang Lửa Về Tim</b>
                <br />
                <br />
                Màn đêm buông lơi theo ánh lửa dần tàn, tình anh em ta theo ánh
                lửa tràn lan.
                <br />
                Tim ta đây còn khắc ghi bao nhiêu mối tình mặn nồng.
                <br />
                Lửa đêm nay tan nhưng lửa thiêng còn cháy âm thầm ngàn đời.
                <br />
                Biệt ly muôn phương ta nguyện đem Lửa Thiêng rải rắc khắp chốn.
                <br />
                Mong mai sau ngọn Lửa Thiêng cháy lên đốt lòng mọi người.
                <br />
                <hr />
                <br />
                <b id="kinhtruockhian">14. Kinh Trước Khi Ăn</b>
                <br />
                <br />
                Nguyện xin, nguyện xin Chúa Cả mở tay chúc phúc,
                <br />
                cho chúng con dùng, cho chúng con dùng nên các thực phẩm này.
                <br />
                <hr />
                <br />
                <b id="kinhsaukhian">15. Kinh Sau Khi Ăn</b>
                <br />
                <br />
                Đội ơn Chúa đời đời mạch sống, đã nuôi chúng con hồn xác hôm
                nay.
                <br />
                Nguyện danh Chúa đời đời cả sáng, xuống cho mọi loài ơn lộc no
                đầy.
                <br />
                <hr />
                <br />
                <b id="catambiet">16. Ca Tạm Biệt</b>
                <br />
                <br />
                Rời tay chúng ta vui lên đường nghĩa vụ. Bạn ơi đừng quên nhé
                phút giây xum vầy.
                <br />
                Tay trong tay mình vui lên nhé. Tim sắt se sầu thương não nề.
                <br />
                Vui ra đi sầu vương trên mắt. Xa cách nhau mình nhớ nhau hoài.
                <br />
                <hr />
                <br />
                <b id="loichua">17. Lời Chúa</b>
                <br />
                <br />
                Lời Chúa là ánh sáng chiếu soi trên mọi loài. Lời Chúa là ánh
                sáng dẫn tôi đi vào đời.
                <br />
                1. Tôi hân hoan bước đi bình an, không lo chi những ngày bão tố.
                <br />
                2. Hôm nay tôi bước đi bình an, tương lai tôi mai ngày rỡ ràng.
                <br />
                <hr />
                <br />
                <b id="lanhnhanloichua">18. Lãnh Nhận Lời Chúa</b>
                <br />
                <br />
                Lãnh nhận lời Chúa em ơi xiết bao mừng vui.
                <br />
                Lãnh nhận ơn chúa em ơi gắng ghi trong lòng.
                <br />
                Em nhớ rằng Thiên chúa yêu em.
                <br />
                Ngài yêu em không bờ bến, Ngài yêu em đến muôn đời.
                <br />
                <hr />
                <br />
                <b id="khauhieuthieunhi">19. Khẩu Hiệu Thiếu Nhi</b>
                <br />
                <br />
                Cầu nguyện, rước lễ, hy sinh, làm tông đồ. Khẩu hiệu thiếu nhi
                em bền tâm tuôn giữ.
                <br />
                Cầu nguyện, rước lễ hy sinh làm tông đồ. Khẩu hiệu thiếu nhi em
                chẳng quên bao giờ.
                <br />
                <hr />
                <br />
                <b id="cauchuyentinhthuong">20. Câu Chuyện Tình Thương</b>
                <br />
                <br />
                • Có anh chàng anh đi (đi) về làng. Anh gặp đàn em bé.
                <br />
                Ra đây anh kể cho câu chuyện tình thương.
                <br />
                • Có một lần, khi xưa (xưa) thật xưa, do lời Thiên Chúa hứa.
                <br />
                Giêsu con một Cha xuống trần làm người.
                <br />
                • Suốt cuộc đời ba mươi năm làm người vâng lời treo gương sáng.
                <br />
                Hai ba năm về sau ra đời dạy lời.
                <br />
                • Giữa muôn lời, lời này thật lạ đời, nhưng là lời hay nhất.
                <br />
                Yêu thương anh chị em như là mình vậy.
                <br />
                • Dẫu cho là người mình đầy cảm tình, hay người không ưa thích,
                <br />
                luôn yêu thương bằng nhau ấy là lời Thầy.
                <br />
                • Kết câu chuyện, anh em tay cầm tay, ta nguyện xin Thiên Chúa
                <br />
                luôn ban cho tình thương, cho đời đẹp nhiều.
                <br />
                • Hãy kết thành vòng tròn tròn thật tròn, ta cùng vui ca hát,
                <br />
                vang lên muôn lời ca. Ca tụng tình thương.
                <br />
                <hr />
                <br />
              </Col>
            </Row>
          </div>
        </Container>

        <TitleBar id="othersongs">Other Songs</TitleBar>
        <Container>
          <div className={styles.songs}>
            <br />
            <b className={styles.categoryTitle}>Các Bài Ca Khen Thưởng</b>
            <br />
            <br />
            <b id="covetay">Cờ Về Tay</b>
            <br />
            <br />
            1. Cờ về tay trong tay lá cờ bay.
            <br />
            Bao hân hoan reo mừng vui sướng thay.
            <br />
            Nguyện từ nay, dưới cờ, luôn hăng say.
            <br />
            Thắng không kiêu, bại không sờn mới hay.
            <br />
            <br />
            2. Cờ về tay, trong tay, sẽ rời tay.
            <br />
            Vui hôm nay, mai buồn, không có hay.
            <br />
            Vì mình xui, mất cờ, nhung không sao.
            <br />
            Cố vươn lên, giật lại cờ mới hay.
            <br />
            <hr />
            <br />
            <b id="tuatrenco">Tua Trên Cờ</b>
            <br />
            <br />
            Tua gắn cờ, cờ có tua, ôi (tên đội) sao mà hay thế!
            <br />
            Tua gắn cờ, cờ có tua, ôi (trên đội) sướng vui tràn trề.
            <br />
            <hr />
            <br />
            <b id="themtua">Thêm Tua</b>
            <br />
            <br />
            Lá cờ là cờ đội ta,
            <br />
            Tua gắn, gắn lên cờ ta...
            <br />
            Chúng ta vui mừng ca hát
            <br />
            Vang lên câu hát chúc mừng:
            <br />
            (tên đội, ex. Tôma, AAA! or Anre, EEE!, etc…)
            <br />
            <hr />
            <br />
            <b id="goghe">Gồ Ghê</b>
            <br />
            <br />
            Gồ ghê, gồ ghê, gồ ghê!
            <br />
            Khen (tên đội) một cái các bạn ơi.
            <br />
            Gồ ghê, gồ ghê, gồ ghê!
            <br />
            Hãy vỗ tay khen (tên đội) một tràng.
            <br />
            <hr />
            <br />
            <b id="hoanhoanhnay">Hoan Hô Anh Này</b>
            <br />
            <br />
            Hoan hô anh này một cái, hoan hô anh này.
            <br />
            Nào chúng mình hoan hô, nào chúng mình hoan hô. Nào ta hoan hô.
            <br />
            <hr />
            <br />
            <b id="hayqua">Hay Quá</b>
            <br />
            <br />
            Hay quá hay quá là hay, xin thưởng cho tràng pháo tay.
            <br />
            Hay quá hay quá là hay, xin thưởng cho một nụ cười.
            <br />
            Hì hì hi, hà hà hà, hồ hồ hồ.
            <br />
            <hr />
            <br />
            <b id="hoanho">Hoan Hô</b>
            <br />
            <br />
            Hoan hô hoan hô chúng ta cùng ca. À a á!
            <br />
            Chúng tôi phục anh rất nhiều rồi. À a á!
            <br />
            Chúng tôi phục anh rất nhiều.
            <br />
            <hr />
            <br />
            <b id="ohay">Ô! Hay!</b>
            <br />
            <br />
            1. Ô hay! Ô hay! Xin chúc mừng cho đội của anh.
            <br />
            Mừng vì được, mừng vì được một cái tua trên cờ.
            <br />
            <br />
            2. Ô hay! Ô hay! Xin chúc mừng cho đội của anh.
            <br />
            Đã cố gắng, đã phấn đấu, mong tiến thêm nghe bồ.
            <br />
            <hr />
            <br />
            <b id="hayghe">Hay Ghê</b>
            <br />
            <br />
            Hay là hay quá, hay là hay ghê, hay không chỗ nào chê hết.
            <br />
            Hay là hay quá, hay là hay ghê, hay không chê chỗ nào.
            <br />
            <hr />
            <br />
            <b id="caituatrenco">Cái Tua Trên Cờ</b>
            <br />
            <br />
            Được cái tua trên vai mình mong cái tua trên cờ.
            <br />
            Ngỡ ngàng tưởng mình mơ, ô hay sướng vui vô bờ.
            <br />
            Clap (3), snap (3), stomp (3), whoot (3).
            <br />
            <hr />
            <br />
            <b id="ongsaosang">Ông Sao Sáng</b>
            <br />
            <br />
            Một ông sao sáng, hai ông sáng sao.
            <br />
            Tôi đố anh chị nào một hơi đếm hết, từ một ông sao sáng đến mười ông
            sáng sao.
            <br />
            (Nếu đúng: Tình tang tang tính, tang tình tang đúng rồi - Nếu sai:
            Tình tang tang tính, tang tình tang bí lù).
          </div>
          <ScrollToTop showUnder={160} duration={1050} topPosition={600}>
            <Button size="sm" className={styles.button}>
              <i className="fa fa-arrow-circle-up" />
            </Button>
          </ScrollToTop>
        </Container>
        <br />
        <br />
        <br />
      </Layout>
    );
  }
}

export default OfficialSongs;

export const pageQuery = graphql`
  query OfficialSongsQuery {
    allOfficialSongsListJson {
      edges {
        node {
          name
          type
          links {
            fileType
            fileLink
          }
          categoryStyle {
            background
            border
          }
          categoryTextStyle {
            color
          }
          updated
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Official Songs',
  path: '/resources/officialsongs',
  category: 'resources',
  description: 'Official songs, ca chinh thuc',
  tags:
    'resource, official songs, ca chinh thuc, bai, hat, thieu nhi tan hanh ca, quoc ca, au nhi ca, thieu nhi ca, nghia si ca, hiep hanh ca, hiep si ca, kinh dang ngay, kinh dang dem, kinh huynh truong, kinh doi truong, goi lua, chao lua thieng, mang lua ve tim, kinh truoc khi an, kinh sau khi an, ca tam biet',
};
